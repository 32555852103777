<template>
  <div class="card">
    <div class="card-header">
      <h1 class="card-header-title">
        <b-icon icon="tags"></b-icon> Wieviele Label fehlen (=überspringen)?
      </h1>
    </div>
    <div class="card-content">
      <b-field>
        <b-select v-model="offset" placeholder="Bitte wählen">
          <option v-for="option in sel" :value="option.id" :key="option.id">
            {{ option.name }}
          </option>
        </b-select>
      </b-field>
      <button
        class="button"
        type="button"
        @click="$parent.close()"
        style="margin-right: 10px;"
      >
        Schließen
      </button>
      <b-button type="is-info" icon-left="tags" @click="printLabels()"
        >Jetzt Label (PDF) generieren</b-button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "LabelsOffset",
  data() {
    return {
      offset: 0,
      sel: []
    }
  },
  created: function() {
    var sel = []
    for (var i = 0; i <= 26; i++) {
      sel.push({ id: i, name: i + " Label" })
    }
    this.sel = sel
  },

  methods: {
    printLabels: function() {
      this.$parent.$parent.printLabels(this.offset);
    }
  }
}
</script>
