<template>
  <div
    id="select-wine"
    style="width: 100%"
  >
    <label>Bitte wählen Sie einen Wein aus</label>
    <b-field>
      <b-autocomplete
        :data="wineList"
        placeholder="Wein auswählen"
        field="title"
        :loading="isFetching"
        @typing="getAsyncData"
        @select="(option) => setWine(option)"
      >
        <template slot-scope="props">
          <div class="media">
            <div class="media-content">
              {{ props.option.producer_name_de }},
              {{ props.option.wine_name_de }}, {{ (props.option.jahr == 0) ? 'n.V.' :  props.option.jahr }},
              {{ props.option.flaschen_pro_einheit }} x
              {{ props.option.menge }} Liter, ArtNr: W{{ props.option.wine_id }}-A{{ props.option.article_id }}
            </div>
          </div>
        </template>
      </b-autocomplete>
    </b-field>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
export default {
  name: "winefind",
  data() {
    return {
      wineList: [],
      isFetching: false,
    };
  },
  props: { wine: Object },
  methods: {
    getAsyncData: debounce(function (searchphrase) {
      if (searchphrase.length < 3) {
        this.data = [];
        return;
      }
      var t = this;
      this.isFetching = true;
      this.$http
        .post("/wines/autocomplete", { search: searchphrase, perpage: 10 })
        .then(function (resp) {
          t.wineList = resp.data.data;
          t.isFetching = false;
        })
        .catch((error) => {
          t.data = [];
          throw error;
        })
        .finally(() => {
          t.isFetching = false;
        });
    }, 200),
    setWine: function (wine) {
      this.$emit("wine-selection-changed", wine);
    },
  },
};
</script>
