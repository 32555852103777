<template>
  <div class="imagebox column is-1 pt-0">
    <div class="preview has-text-centered" v-if="previewImage">
      <a :href="largeImage" target="_blank"><img :src="previewImage" height="80" /></a>
      <a class="deletelink" @click.prevent="deleteImage()" v-if="inputmode">
        <b-icon icon="delete_forever"></b-icon>
      </a>
      <a class="leftlink" @click.prevent="moveImageLeft()" v-if="inputmode">
        <b-icon icon="arrow-left"></b-icon>
      </a>
      <a class="rightlink" @click.prevent="moveImageRight()" v-if="inputmode">
        <b-icon icon="arrow-right"></b-icon>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageShow",
  data() {
    return {
      previewImage: false,
      largeImage: false
    }
  },
  props: {
    imageIndex: [Number, String],
    image: [String],
    consignmentId: [Number, String],
    inputmode: [Boolean, Number]
  },
  created: function () {
    if (
      typeof this.image == "string" &&
      this.image.length >= 14 &&
      this.image.length < 50
    ) {
      //console.log('alles da');
      const path = this.getPath(this.image, this.consignmentId)
      this.previewImage = path + "_s.jpg"
      this.largeImage = path + "_l.jpg"
    }
  },

  methods: {
    deleteImage: function () {
      //console.log('Werde löschen '+this.image + ' Index: '+this.imageIndex)    ;
      this.$emit("delete-image", this.image)
      //this.$destroy()
      //this.$el.parentNode.removeChild(this.$el)
    },
    moveImageLeft: function () {
      //console.log('Werde löschen '+this.image + ' Index: '+this.imageIndex)    ;
      this.$emit("move-image", this.image,'left')
      //this.$destroy()
      //this.$el.parentNode.removeChild(this.$el)
    },
     moveImageRight: function () {
      //console.log('Werde löschen '+this.image + ' Index: '+this.imageIndex)    ;
      this.$emit("move-image", this.image, 'right')
      //this.$destroy()
      //this.$el.parentNode.removeChild(this.$el)
    },
    getPath: function (id, consignment_id) {
      var path = ""
      if (id.substr(0, 12) != "/tmpuploads/") {
        path += "/wines/"
        path += consignment_id % 100
        path += "/" + consignment_id + "/"
      } else {
        path = this.$store.getters.config.page.imageBaseUrl
      }
      path += id
      return path
    }
  }
}
</script>

<style scoped>
div.preview a.deletelink {
  display: block;
  position: absolute;
  margin-top: -30px;
  margin-bottom: 0px;
  right: 32px;
  left: 32px;;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: rgba(230, 20, 20, 0.7);
  color: #ffffff;
  z-index: 1000;
}
div.preview a.leftlink {
  display: block;
  position: absolute;
  margin-top: -30px;
  margin-bottom: 0px;
  left: 4px;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: rgba(20, 30, 90, 0.7);
  color: #ffffff;
  z-index: 1000;
}
div.preview a.rightlink {
  display: block;
  position: absolute;
  margin-top: -30px;
  margin-bottom: 0px;
  right: 4px;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: rgba(20,30,90, 0.7);
  color: #ffffff;
  z-index: 1000;
}
div.preview{
  position: relative;
}
</style>
