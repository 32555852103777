<template>
  <div style="width: 100%" id="wine.key">
    <wine-find
      :wine="wine"
      v-if="!wineSelected"
      @wine-selection-changed="wineSelectionChanged"
    ></wine-find>
    <div
      class="wine-data"
      v-if="wineSelected"
      style="width: 100%; max-width: 1344px"
    >
      <div>
                   
              <b>  {{ w.article }}</b>
        <span
          v-if="
            typeof w.consignment != 'undefined' &&
            typeof w.consignment == 'object'
          "
          class="smallgrey"
        >
          <br />Aus Ankauf/Vorgang:
          <router-link :to="'/consignments/view/' + w.consignment.id"
            >{{ this.$utilities.padLeftWithNull(w.consignment_id) }} /
            {{ this.$utilities.padLeftWithNull(w.consignment_sequence_id) }}</router-link
          >
          <span v-if="w.consignment.info != null && w.consignment.info.length >= 2">
            ({{ w.consignment.info }})</span
          >
          <div v-if="w.from_private ==1" class="has-text-danger">
             Achtung: Differenzbesteuerung</div
            >
        </span>
      </div>
      <div class="columns mb-0">
        <div class="column is-1">
          <b-field label="Flaschen" v-if="inputmode">
            <b-select v-model.number="w.bottles" size="is-small">
              <option
                v-for="option in selectBottles"
                :value="option.id"
                :key="option.id"
              >
                {{ option.name }}
              </option>
            </b-select>
          </b-field>
          <label v-if="!inputmode">Flaschen</label>
          <div v-if="!inputmode">{{ w.bottles }} von {{ w.original_bottles }}
          <span v-if="typeof w.reserved_bottles != 'undefined'"><br> {{ w.reserved_bottles }}&nbsp;res.</span></div>
        </div>
        <div class="column is-1 text-right">
          <b-field :label="'EK-Preis/Fl.'" v-if="inputmode">
            <b-field>
              <b-input
                size="is-small"
                v-model="w.price_ek"
                custom-class="has-text-right"
              ></b-input>
              <p class="control">
                <span class="button is-static is-small">€</span>
              </p>
            </b-field>
          </b-field>
          <label v-if="!inputmode">{{ "EK-Preis/Fl." }}</label>
          <div v-if="!inputmode">{{ w.price_ek }}€</div>
        </div>

      
        <div class="column is-1">
          <b-field label="Label" v-if="inputmode ">
            <b-select v-model="w.label" size="is-small">
              <option
                v-for="option in $utilities.getList('label')"
                :value="option.id"
                :key="option.id"
              >
                {{ option.name }}
              </option>
            </b-select>
          </b-field>
          <label v-if="!inputmode">Label</label>
          <div v-if="!inputmode ">
            {{ $utilities.getValue("label", w.label) }}
          </div>
        </div>
        <div class="column is-1">
          <b-field label="Kapsel" v-if="inputmode">
            <b-select v-model="w.capsula" size="is-small">
              <option
                v-for="option in $utilities.getList('capsula')"
                :value="option.id"
                :key="option.id"
              >
                {{ option.name }}
              </option>
            </b-select>
          </b-field>
          <label v-if="!inputmode">Kapsel</label>
          <div v-if="!inputmode">
            {{ $utilities.getValue("capsula", w.capsula) }}
          </div>
        </div>
        <div class="column is-1">
          <b-field label="Füllstand" v-if="inputmode ">
            <b-select v-model="w.fill_level" size="is-small">
              <option
                v-for="option in $utilities.getList('fill_level')"
                :value="option.id"
                :key="option.id"
              >
                {{ option.name }}
              </option>
            </b-select>
          </b-field>
          <label v-if="!inputmode  ">Füllstand</label>
          <div v-if="!inputmode">
            {{ $utilities.getValue("fill_level", w.fill_level) }}
          </div>
        </div>
        <div class="column is-1">
          <b-field label="Verpackung" v-if="inputmode">
            <b-select v-model="w.packaging" size="is-small">
              <option
                v-for="option in $utilities.getList('packaging')"
                :value="option.id"
                :key="option.id"
              >
                {{ option.name }}
              </option>
            </b-select>
          </b-field>
          <label v-if="!inputmode ">Verpackung</label>
          <div v-if="!inputmode ">
            {{ $utilities.getValue("packaging", w.packaging) }}
          </div>
        </div>
        <div class="column is-2">
          <b-field label="Sonstiges deutsch" v-if="inputmode">
            <b-input
              v-model="w.info_de"
              maxlength="200"
              size="is-small"
            ></b-input>
          </b-field>
          <label v-if="!inputmode">Sonstiges deutsch</label>
          <div v-if="!inputmode">{{ w.info_de }}</div>
        </div>

        <div class="column is-2">
          <b-field label="Sonstiges english" v-if="inputmode">
            <b-input
              v-model="w.info_en"
              maxlength="200"
              size="is-small"
            ></b-input>
          </b-field>
          <label v-if="!inputmode">Sonstiges english</label>
          <div v-if="!inputmode">{{ w.info_en }}</div>
        </div>
        <div class="column is-2 has-text-right">
          <b-field label="Aktion" v-if="inputmode">
            <b-dropdown
              append-to-body
              aria-role="menu"
              class="is-small"
              size="is-small"
            >
              <template #trigger>
                <a class="" role="button">
                  <b-icon icon="dots-three-vertical"></b-icon>
                </a>
              </template>

              <b-dropdown-item has-link aria-role="menuitem"
                ><a @click.prevent="wineCopy(w.key, 1)"
                  >1x Kopieren</a
                ></b-dropdown-item
              >
              <b-dropdown-item has-link aria-role="menuitem"
                ><a @click.prevent="wineCopy(w.key, 2)"
                  >2x Kopieren</a
                ></b-dropdown-item
              >
              <b-dropdown-item has-link aria-role="menuitem"
                ><a @click.prevent="wineCopy(w.key, 5)"
                  >5x Kopieren</a
                ></b-dropdown-item
              >
              <b-dropdown-item has-link aria-role="menuitem"
                ><a @click.prevent="wineCopy(w.key, 11)"
                  >11x Kopieren</a
                ></b-dropdown-item
              >
              <b-dropdown-item has-link aria-role="menuitem"
                ><a @click.prevent="wineCopy(w.key, 23)"
                  >23x Kopieren</a
                ></b-dropdown-item
              >
              <b-dropdown-item has-link aria-role="menuitem"
                ><a @click.prevent="wineCopy(w.key, 35)"
                  >35x Kopieren</a
                ></b-dropdown-item
              >
              <b-dropdown-item has-link aria-role="menuitem"
                ><a @click.prevent="wineCopy(w.key, 47)"
                  >47x Kopieren</a
                ></b-dropdown-item
              >
              <b-dropdown-item has-link aria-role="menuitem"
                ><a @click.prevent="wineCopy(w.key, 59)"
                  >59x Kopieren</a
                ></b-dropdown-item
              >

              <b-dropdown-item has-link aria-role="menuitem"
                ><a @click.prevent="wineDelete(w.key)" class="has-text-danger"
                  >Löschen</a
                ></b-dropdown-item
              >
            </b-dropdown>
          </b-field>

          <label v-if="!inputmode && auctionview">Lagerort/ID</label>
          <div v-if="!inputmode && auctionview">
            <b>{{ w.stockplace }}</b
            ><br />
            {{ $utilities.padLeftWithNull(w.id) }}
          </div>
        </div>
      </div>
      <div class="images columns is-multiline" >
        <image-show
          v-for="(i, index) in images"
          :key="'image-' + imageRounds + '-' + index"
          :imageIndex="index"
          :image="i"
          :consignmentId="w.consignment_id"
          :inputmode="inputmode"
          v-on:delete-image="deleteImage"
          v-on:move-image="moveImage"
        />

        <image-upload v-if="inputmode && images.length < 10" />
      </div>
    </div>
  </div>
</template>

<script>
import WineFind from "../_helpers/winefind.vue"
import ImageUpload from "../_helpers/imageupload.vue"
import ImageShow from "../_helpers/imageshow.vue"
export default {
  name: "Winefield",
  components: {
    WineFind,
    ImageUpload,
    ImageShow
  },
  data() {
    return {
      nf: Intl.NumberFormat('en-US', {
  
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}),
      pricechangemode: false,
      wine: {},
      wineSelected: false,
      selectBottles: this.selectBottlesRange(),
      imageRounds: 0
    }
  },

  props: {
    wineIndex: { type: [Number, String], required: true },
    w: { type: Object, required: true },
    inputmode: { type: Boolean, required: true, default: true },
    auctionview: { type: Boolean, required: false, default: false },
    priceChangeAllowed:{ type: Boolean, required: false, default: false }
  },
  computed: {
    images: function () {
      return this.w.images
    }
  },
  watch: {
    images: {
      deep: true,
      handler() {
        this.imageRounds++
      }
    }
  },
  created: function () {
    //console.log("Winefield created")
    //console.log(this.w)
    //console.log(typeof this.w.article)
    if (typeof this.w.article == "string" && this.w.article.length >= 5) {
      this.wineSelected = true
      this.w.bottles = parseInt(this.w.bottles)

      if (typeof this.w.images == "string") {
        //console.log("Fucking string")
        this.w.images = []
      }
    }
    /*
    if (typeof this.w.article == "string" && this.w.article.length >= 5) {
      this.wineSelected = true
      this.w.bottles = parseInt(this.w.bottles)

      //Neues Upload-Feld hinzufügen
      if (
        this.inputmode &&
        (this.w.images.length == 0 || this.w.images.indexof("-") == -1)
      ) {
        //console.log('Füge Bild-Feld hinzu '+this.w.article);
        //console.log(typeof this.w.images);
        if (typeof this.w.images == "string") {
          this.w.images = []
        }
        this.nextImageUploadField()
      }
    }*/
  },

  methods: {
    selectBottlesRange: function () {
      var sel = []
      for (var i = 1; i <= 60; i++) {
        sel.push({ id: i, name: i + " Fl." })
      }
      return sel
    },
    wineSelectionChanged: function (wine) {
      if (typeof wine == "object") {
        var jahr = wine.jahr == 0 ? "n.V." : wine.jahr
        this.w.article_id = wine.article_id
        this.w.wine_id = wine.wine_id
        this.w.article =
          wine.producer_name_de +
          ", " +
          wine.wine_name_de +
          ", " +
          jahr +
          ", " +
          wine.flaschen_pro_einheit +
          " x " +
          wine.menge
        this.wineSelected = true
        this.$parent.winesSelected = true
        this.w.images = []
        this.w.info_de = ""
        this.w.info_en = ""
        //this.nextImageUploadField()
      } else {
        this.wine = {}
        this.wineSelected = false
      }
    },

    wineDelete: function () {
      //console.log("Winefield Delete " + this.wineIndex)
      this.w.bottles = 0
      this.w.deleted = 1
      this.w.article_id = 0
      this.w.wine_id = 0
      this.$emit("wine-delete", this.wineIndex)
      this.$destroy()
      this.$el.parentNode.removeChild(this.$el)
    },

    wineCopy: function (id, no) {
      //console.log("Winefield Copy " + id + ", no: " + no)
      this.$emit("wine-copy", this.wineIndex, no)
    },
    getImages: function (i) {
      var images = i
      var basePath = "/wines/"
      basePath += this.w.consignment_id % 100
      basePath += "/" + this.w.consignment_id + "/"
      var html = ""

      for (var c = 0; c < images.length; c++) {
        if (images[c].length > 10) {
          html +=
            '<div class="column is-1"><a href="' +
            basePath +
            images[c] +
            '_l.jpg" target="_blank"><img src="' +
            basePath +
            images[c] +
            '_s.jpg" alt="" /></a></div>'
        }
      }

      return html
    },
    togglePrice: function () {
      this.pricechangemode = true;
      
    },
    savePrice: function () {
      //console.log('savePrice', this.w.price_vk);
       var t = this
      t.$http
        .post("/stocks/update-price", {
          s: t.w,
        })
        .then(function(resp) {
          t.pricechangemode = false;
          t.w = resp.data.data;
          t.$parent.stockArticle = resp.data.data;
          return true
        })
        .catch(function(error) {
          
          alert('Es ist ein unbekannter Fehler aufgetreten');
          
          return false
        })
      
      
    },
    addImage: function (image, index) {
      //console.log("Füge bild hinzu " + index + " " + image)
      this.w.images.push(image)
    },
    deleteImage: function (image) {
      //console.log("Lösche " + image);
      //console.log(this.w.images)
      var t = this
      var newImages = []
      for (var i = 0; i < t.w.images.length; i++) {
        if (t.w.images[i] == image) {
          continue
        }
        newImages.push(t.w.images[i])
      }
      t.w.images = []
      t.w.images = newImages
      //console.log(this.w.images)
    },
    moveImage: function (image, direction) {
      //console.log("Lösche " + image);
      //console.log("Verschieben " + image + " nach " + direction)
      var t = this
      var newImages = t.w.images
      var oldImage = false
      var imageToMove = false
      for (var i = 0; i < t.w.images.length; i++) {
        //Bild gefunden
        if (t.w.images[i] == image) {
          //console.log("gefunden " + i)
          if (direction == "left" && i >= 1) {
            oldImage = newImages[i - 1]
            newImages[i - 1] = newImages[i]
            newImages[i] = oldImage
          } else if (direction == "right" && i < newImages.length - 1) {
            oldImage = newImages[i + 1]
            newImages[i + 1] = newImages[i]
            newImages[i] = oldImage
          }
          break
        }
      }
      t.w.images = []
      t.w.images = newImages
      //console.log(this.w.images)
    }
  }
}
</script>
