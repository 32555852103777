<template>
  <div id="customer-field" class="">
    <div class="column">
      Kundennummer:
      {{ typeof customer.id != "undefined" ? customer.id : customer.customer_id
      }}<br />
      {{ customer.name }} <br />
      {{ customer.adress1 }} <br />
      <span v-if="customer.adress2 != null && customer.adress2.length > 2"
        >{{ customer.adress2 }} <br
      /></span>
      {{ customer.zip }} {{ customer.city }} ({{ getCountryName() }})
    </div>
    <div class="column">
      <b-icon icon="email2"></b-icon>
      <a :href="'mailto:' + customer.email">{{ customer.email }}</a>
      <br />
      <b-icon icon="phonelocal_phone"></b-icon>
      <a :href="'tel:' + customer.telephone">{{ customer.telephone }}</a>
      <br />
      <b-icon icon="mobile"></b-icon>
      <a :href="'tel:' + customer.mobile">{{ customer.mobile }}</a>
      <span v-if="typeof customer.language != 'undefined'"><br><b-icon icon="sphere"></b-icon>
      Sprache: {{customer.language }}</span>
      <br />
      <b-button
        v-if="changeLink"
        class="button"
        size="is-small"
        type="is-default"
        @click="resetCustomer()"
        icon-left="square-edit-outline"
        >Ändern</b-button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "Customerfield",
  data() {
    return {}
  },

  props: {
    customer: {
      type: Object,
      required: true
    },
    changeLink: Boolean
  },

  methods: {
    resetCustomer: function() {
      this.$emit("customer-selection-changed", false)
    },
    getCountryName: function() {
      if (typeof this.customer.name_de != "undefined") {
        return this.customer.name_de
      }
      if (typeof this.customer.country != "undefined" && typeof this.customer.country.name_de != "undefined") {
        return this.customer.country.name_de
      }
      if (typeof this.customer.country_name_de != "undefined") {
        return this.customer.country_name_de
      }
      return ""
    }
  }
}
</script>
